import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { MRT_ColumnDef } from "material-react-table";
import { RequestData } from "../../api/type";
import { LABELS } from "../../utils/constants";
import { createStatusColumn, createUoMColumn } from "../../utils/helpers";

dayjs.extend(utc);

// For Internal User - Start
export const OEM_LAYOUT_COLUMNS: MRT_ColumnDef<RequestData>[] = [
  // {
  //   accessorFn: (row) => dayjs(row.requested_date).format("MM/DD/YYYY"),
  //   accessorKey: "requested_date",
  //   header: LABELS.REQUESTED_DATE,
  //   enableColumnFilter: false,
  // },
  // {
  //   accessorFn: (row) => `${row.distributor_seller_first_name} ${row.distributor_seller_last_name}`,
  //   id: "name",
  //   header: LABELS.FULL_NAME,
  //   enableColumnFilter: false,
  //   enableSorting: false,
  // },
  // { accessorKey: "requested_by", header: LABELS.REQUESTED_BY },
  { accessorKey: "id", header: LABELS.REQUESTED_ID },
  { accessorKey: "entity_name", header: LABELS.BUSINESS_ENTITY },
  createStatusColumn<RequestData>("status", LABELS.STATUS),
  { accessorKey: "sold_to_number", header: LABELS.SOLD_NUMBER },
  { accessorKey: "distributor_name", header: `${LABELS.DISTRIBUTOR} ${LABELS.NAME}` },
  { accessorKey: "location", header: `${LABELS.DISTRIBUTOR} ${LABELS.LOCATION}`, size: 230 },
  {
    accessorKey: "distributor_customer",
    header: `${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER}  ${LABELS.NAME}`,
    size: 280,
  },
  {
    accessorFn: (row) => `${row.distributor_seller_first_name} ${row.distributor_seller_last_name}`,
    id: "distributor_seller",
    header: LABELS.DISTRIBUTOR_SELLER,
    size: 200,
  },
  { accessorKey: "customer_number", header: `${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}`, size: 280 },
  { accessorKey: "country_name", header: LABELS.COUNTRY },
  { accessorKey: "state_name", header: LABELS.STATE },
  { accessorKey: "city", header: LABELS.CITY },
  { accessorKey: "seller_name", header: `${LABELS.COVESTRO_SELLER} ${LABELS.NAME}`, size: 240 },
  { accessorKey: "oem_name", header: LABELS.OEM_NAME },
  { accessorKey: "sales_text", header: LABELS.PRODUCT_SALES_TEXT, size: 260 },
  { accessorKey: "product_id", header: LABELS.COVESTRO_PRODUCT_NUMBER, size: 260 },
  { accessorKey: "product_name", header: LABELS.COVESTRO_PRODUCT },
  { accessorKey: "distributor_product_number", header: LABELS.DISTRIBUTOR_PRODUCT_NUMBER, size: 260 },
  {
    accessorFn: (row) => dayjs.utc(row.valid_from).toDate(),
    accessorKey: "valid_from",
    header: LABELS.VALID_FROM,
    Cell: ({ cell }) => dayjs.utc(String(cell.getValue())).format("MM/DD/YYYY"),
    filterVariant: "date",
  },
  {
    accessorFn: (row) => dayjs.utc(row.valid_to).toDate(),
    accessorKey: "valid_to",
    header: LABELS.VALID_TO,
    Cell: ({ cell }) => dayjs.utc(String(cell.getValue())).format("MM/DD/YYYY"),
    enableColumnFilter: false,
  },
  { accessorKey: "minimum_quantity", header: LABELS.MINIMUM_QTY_PURCHASE, size: 280 },
  createUoMColumn<RequestData>("unit_of_measurement", LABELS.UNIT_OF_MEASUREMENT),
  { accessorKey: "list_price", header: LABELS.LIST_PRICE },
  { accessorKey: "list_discount", header: `${LABELS.LIST_PRICE} ${LABELS.DISCOUNT}`, size: 240 },
  { accessorKey: "requested_price", header: `${LABELS.REQUESTED} ${LABELS.PRICE}` },
  { accessorKey: "requested_discount", header: `${LABELS.REQUESTED} ${LABELS.DISCOUNT}`, size: 240 },
  { accessorKey: "adjustment", header: LABELS.ADJUSTMENT },
];

export const NON_OEM_LAYOUT_COLUMNS: MRT_ColumnDef<RequestData>[] = [
  { accessorKey: "id", header: LABELS.REQUESTED_ID },
  { accessorKey: "entity_name", header: LABELS.BUSINESS_ENTITY },
  createStatusColumn<RequestData>("status", LABELS.STATUS),
  { accessorKey: "sold_to_number", header: LABELS.SOLD_NUMBER },
  { accessorKey: "distributor_name", header: `${LABELS.DISTRIBUTOR} ${LABELS.NAME}` },
  { accessorKey: "location", header: `${LABELS.DISTRIBUTOR} ${LABELS.LOCATION}`, size: 230 },
  {
    accessorKey: "distributor_customer",
    header: `${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER}  ${LABELS.NAME}`,
    size: 270,
  },
  {
    accessorFn: (row) => `${row.distributor_seller_first_name} ${row.distributor_seller_last_name}`,
    id: "distributor_seller",
    header: LABELS.DISTRIBUTOR_SELLER,
    size: 200,
  },
  { accessorKey: "customer_number", header: `${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}`, size: 270 },
  { accessorKey: "country_name", header: LABELS.COUNTRY },
  { accessorKey: "state_name", header: LABELS.STATE },
  { accessorKey: "city", header: LABELS.CITY },
  { accessorKey: "seller_name", header: `${LABELS.COVESTRO_SELLER} ${LABELS.NAME}`, size: 240 },
  { accessorKey: "sales_text", header: LABELS.PRODUCT_SALES_TEXT, size: 260 },
  { accessorKey: "product_id", header: LABELS.COVESTRO_PRODUCT_NUMBER, size: 270 },
  { accessorKey: "product_name", header: LABELS.COVESTRO_PRODUCT },
  { accessorKey: "distributor_product_number", header: LABELS.DISTRIBUTOR_PRODUCT_NUMBER, size: 260 },
  {
    accessorFn: (row) => dayjs.utc(row.valid_from).toDate(),
    accessorKey: "valid_from",
    header: LABELS.VALID_FROM,
    Cell: ({ cell }) => dayjs.utc(String(cell.getValue())).format("MM/DD/YYYY"),
    filterVariant: "date",
  },
  {
    accessorFn: (row) => dayjs.utc(row.valid_to).toDate(),
    accessorKey: "valid_to",
    header: LABELS.VALID_TO,
    Cell: ({ cell }) => dayjs.utc(String(cell.getValue())).format("MM/DD/YYYY"),
    enableColumnFilter: false,
  },
  { accessorKey: "minimum_quantity", header: LABELS.MINIMUM_QTY_PURCHASE, size: 270 },
  createUoMColumn<RequestData>("unit_of_measurement", LABELS.UNIT_OF_MEASUREMENT),
  { accessorKey: "into_stock_price", header: LABELS.INTO_STOCK_PRICE },
  { accessorKey: "support_price", header: LABELS.SUPPORT_PRICE },
  { accessorKey: "adjustment", header: LABELS.ADJUSTMENT },
];
// For Internal User - End

// For External User - Start
export const DEFAULT_COLUMNS: MRT_ColumnDef<RequestData>[] = [
  { accessorKey: "id", header: LABELS.REQUESTED_ID },
  { accessorKey: "entity_name", header: LABELS.BUSINESS_ENTITY },
  // {
  //   accessorFn: (row) => dayjs(row.requested_date).format("MM/DD/YYYY"),
  //   accessorKey: "requested_date",
  //   header: LABELS.REQUESTED_DATE,
  //   enableColumnFilter: false,
  // },
  // {
  //   accessorFn: (row) => `${row.distributor_seller_first_name} ${row.distributor_seller_last_name}`,
  //   id: "name",
  //   header: LABELS.FULL_NAME,
  //   enableColumnFilter: false,
  //   enableSorting: false,
  // },
  // { accessorKey: "requested_by", header: LABELS.REQUESTED_BY},
  { accessorKey: "sold_to_number", header: LABELS.SOLD_NUMBER },
  { accessorKey: "distributor_name", header: `${LABELS.DISTRIBUTOR} ${LABELS.NAME}` },
  { accessorKey: "location", header: `${LABELS.DISTRIBUTOR} ${LABELS.LOCATION}`, size: 230 },
  { accessorKey: "distributor_customer", header: `${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER} ${LABELS.NAME}`, size: 280 },
  {
    accessorFn: (row) => `${row.distributor_seller_first_name} ${row.distributor_seller_last_name}`,
    id: "distributor_seller",
    header: LABELS.DISTRIBUTOR_SELLER,
    size: 200,
  },
  { accessorKey: "customer_number", header: `${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}`, size: 270 },
  { accessorKey: "country_name", header: LABELS.COUNTRY },
  { accessorKey: "state_name", header: LABELS.STATE },
  { accessorKey: "city", header: LABELS.CITY },
  { accessorKey: "seller_name", header: `${LABELS.COVESTRO_SELLER} ${LABELS.NAME}`, size: 240 },
  { accessorKey: "oem_name", header: LABELS.OEM_NAME },
  { accessorKey: "sales_text", header: LABELS.PRODUCT_SALES_TEXT, size: 260 },
  { accessorKey: "product_id", header: LABELS.COVESTRO_PRODUCT_NUMBER, size: 260 },
  { accessorKey: "product_name", header: LABELS.COVESTRO_PRODUCT },
  { accessorKey: "distributor_product_number", header: LABELS.DISTRIBUTOR_PRODUCT_NUMBER, size: 260 },
  {
    accessorFn: (row) => dayjs.utc(row.valid_from).toDate(),
    accessorKey: "valid_from",
    header: LABELS.VALID_FROM,
    Cell: ({ cell }) => dayjs.utc(String(cell.getValue())).format("MM/DD/YYYY"),
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) => dayjs.utc(row.valid_to).toDate(),
    accessorKey: "valid_to",
    header: LABELS.VALID_TO,
    Cell: ({ cell }) => dayjs.utc(String(cell.getValue())).format("MM/DD/YYYY"),
    enableColumnFilter: false,
  },
  { accessorKey: "minimum_quantity", header: LABELS.MINIMUM_QTY_PURCHASE, size: 280 },
  createUoMColumn<RequestData>("unit_of_measurement", LABELS.UNIT_OF_MEASUREMENT),
  { accessorKey: "list_price", header: LABELS.LIST_PRICE },
  { accessorKey: "list_discount", header: `${LABELS.LIST_PRICE} ${LABELS.DISCOUNT}`, size: 240 },
  { accessorKey: "requested_price", header: `${LABELS.REQUESTED} ${LABELS.PRICE}` },
  { accessorKey: "requested_discount", header: `${LABELS.REQUESTED} ${LABELS.DISCOUNT}`, size: 240 },
  { accessorKey: "into_stock_price", header: LABELS.INTO_STOCK_PRICE },
  { accessorKey: "support_price", header: LABELS.SUPPORT_PRICE },
  { accessorKey: "adjustment", header: LABELS.ADJUSTMENT },
];
// For External User - End

export const initialColumnFilter = [{ id: "status", value: [LABELS.SUBMITTED] }];
export const initialSortingFilter = [{ id: "id", desc: true }];
