import { ExportToExcel, Filter } from "../../store/slices";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown, generateParamsForExport } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import DistributorLocationData, { ApiResponse } from "./types";

const distributorLocationApi = {
  getDistributorLocation(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<DistributorLocationData>>(API.DISTRIBUTOR_LOCATION, {
      params,
      headers: { noLoader: "true" },
    });
  },
  getDistributorLocationForExport(filters: ExportToExcel) {
    const params = generateParamsForExport(filters);
    return axios.get<ApiResponse<DistributorLocationData>>(API.DISTRIBUTOR_LOCATION, { params });
  },
  updateDistributorLocation(payload: DistributorLocationData) {
    const { id, distributor_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<DistributorLocationData>>(`${API.DISTRIBUTOR_LOCATION}/${id}`, payloadWithoutId);
  },
  createDistributorLocation(payload: DistributorLocationData) {
    return axios.post<ApiResponse<DistributorLocationData>>(API.DISTRIBUTOR_LOCATION, payload);
  },
  getDistributorLocationForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<DistributorLocationData>>(API.DISTRIBUTOR_LOCATION, { params });
  },
};

export default distributorLocationApi;
