import { ExportToExcel, Filter } from "../../store/slices";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown, generateParamsForExport } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import CompetitorData, { ApiResponse } from "./types";

const competitorApi = {
  getCompetitor(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<CompetitorData>>(API.COMPETITOR, {
      params,
      headers: { noLoader: "true" },
    });
  },
  getCompetitorForExport(filters: ExportToExcel) {
    const params = generateParamsForExport(filters);
    return axios.get<ApiResponse<CompetitorData>>(API.COMPETITOR, { params });
  },
  updateCompetitor(payload: CompetitorData) {
    const { id, distributor_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<CompetitorData>>(`${API.COMPETITOR}/${id}`, payloadWithoutId);
  },
  createCompetitor(payload: CompetitorData) {
    return axios.post<ApiResponse<CompetitorData>>(API.COMPETITOR, payload);
  },
  getCompetitorForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<CompetitorData>>(API.COMPETITOR, { params });
  },
};

export default competitorApi;
