import { FC, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { DataTable, PageTitle, ContainerGrid } from "../../components";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { showModal, MODAL_ACTION_ADD, MODAL_ACTION_EDIT, triggerRowAction } from "../../store/slices";
import { generatePageClass } from "../../utils/helpers";
import { RootState } from "../../store";

interface AddOrEditModalProps {
  handleSubmit: (formData: any) => void;
}

interface Props {
  title: string;
  dataTable: Array<any>;
  totalRows: number;
  columns: any;
  AddOrEditModal: FC<AddOrEditModalProps>;
  handleSubmit: (formData: any) => void;
  handleExportToExcel: () => void;
  isShowNewBtn?: boolean;
}

const CommonPageLayout = ({
  title,
  dataTable,
  totalRows,
  columns,
  AddOrEditModal,
  handleSubmit,
  handleExportToExcel,
  isShowNewBtn = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const { rowAction } = useAppSelector((state: RootState) => state.dataTable);

  useEffect(() => {
    if (rowAction) {
      const { data } = rowAction;
      dispatch(showModal({ type: MODAL_ACTION_EDIT, data }));
      // Reset action after handling
      dispatch(triggerRowAction(null));
    }
  }, [rowAction, dispatch]);

  return (
    <Box className={generatePageClass(title)}>
      <PageTitle
        title={title}
        handleBtnNew={() => dispatch(showModal({ type: MODAL_ACTION_ADD }))}
        isShowNewBtn={isShowNewBtn}
      />
      <ContainerGrid>
        <Grid size={12}>
          <DataTable
            data={dataTable}
            totalRows={totalRows}
            columns={columns}
            exportFileName={title}
            handleExportToExcel={handleExportToExcel}
          />
        </Grid>
      </ContainerGrid>
      <AddOrEditModal handleSubmit={handleSubmit} />
    </Box>
  );
};

export default CommonPageLayout;
