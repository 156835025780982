import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from "material-react-table";

interface RowActionPayload {
  data: any;
}

export interface Filter {
  column: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
  user_type?: string;
  email?: string;
}

export interface ExportToExcel {
  column: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  user_type?: string;
  email?: string;
}

export interface DataTableState {
  isError: boolean;
  isRefetching: boolean;
  isResetting: boolean;
  filters: Filter;
  rowAction?: any;
}

const initialState: DataTableState = {
  isError: false,
  isRefetching: false,
  isResetting: false,
  filters: {
    column: [],
    sorting: [],
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
  },
  rowAction: null as RowActionPayload | null,
};

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState,
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<{
        column?: MRT_ColumnFiltersState;
        sorting?: MRT_SortingState;
        pagination?: MRT_PaginationState;
      }>
    ) => {
      const { column, sorting, pagination } = action.payload;
      if (column !== undefined) state.filters.column = column;
      if (sorting !== undefined) state.filters.sorting = sorting;
      if (pagination !== undefined) state.filters.pagination = pagination;
    },
    showLoader: (state) => {
      state.isRefetching = true;
    },
    hideLoader: (state) => {
      state.isRefetching = false;
    },
    resetDataTableToInitialState: (state) => {
      state.isResetting = true;
      state.filters = initialState.filters;
    },
    finishResetting: (state) => {
      state.isResetting = false;
    },
    triggerRowAction: (state, action: PayloadAction<RowActionPayload | null>) => {
      state.rowAction = action.payload;
    },
  },
});

export const { setFilters, showLoader, hideLoader, resetDataTableToInitialState, finishResetting, triggerRowAction } =
  dataTableSlice.actions;

export const dataTableReducer = dataTableSlice.reducer;
