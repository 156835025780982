import { createTitle } from "../helpers";
import { ROUTE_PATHS } from "./routePath";
import { EXTERNAL, INTERNAL } from "./userRoles";

const ROLES = [INTERNAL, EXTERNAL];

export const NAV_LINKS = [
  {
    title: createTitle(ROUTE_PATHS.MY_ACCOUNT),
    path: `/${ROUTE_PATHS.MY_ACCOUNT}`,
  },
  {
    title: createTitle(ROUTE_PATHS.REQUESTS),
    path: `/${ROUTE_PATHS.REQUESTS}`,
  },
  {
    title: createTitle(ROUTE_PATHS.APPLICATION_MANAGEMENT),
    path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}`,
    subLinks: [
      {
        title: createTitle(ROUTE_PATHS.BUSINESS_ENTITY),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.BUSINESS_ENTITY}`,
      },
      {
        title: createTitle(ROUTE_PATHS.COMPETITOR),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.COMPETITOR}`,
      },
      {
        title: createTitle(ROUTE_PATHS.COUNTRY),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.COUNTRY}`,
      },
      {
        title: createTitle(ROUTE_PATHS.COVESTRO_SELLER),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.COVESTRO_SELLER}`,
      },
      {
        title: createTitle(ROUTE_PATHS.DISTRIBUTOR),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.DISTRIBUTOR}`,
      },
      {
        title: createTitle(ROUTE_PATHS.DISTRIBUTOR_CONTACT),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.DISTRIBUTOR_CONTACT}`,
      },
      {
        title: createTitle(ROUTE_PATHS.DISTRIBUTOR_CUSTOMER),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.DISTRIBUTOR_CUSTOMER}`,
      },
      {
        title: createTitle(ROUTE_PATHS.DISTRIBUTOR_LOCATION),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.DISTRIBUTOR_LOCATION}`,
      },
      {
        title: createTitle(ROUTE_PATHS.MARKET),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.MARKET}`,
      },
      {
        title: "OEM",
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.OEM}`,
      },
      {
        title: createTitle(ROUTE_PATHS.STATE),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.STATE}`,
      },
    ],
  },
];
