import { ExportToExcel, Filter } from "../../store/slices";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown, generateParamsForExport } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import StateData, { ApiResponse } from "./types";

const stateApi = {
  getState(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<StateData>>(API.STATE, { params, headers: { noLoader: "true" } });
  },
  getStateForExport(filters: ExportToExcel) {
    const params = generateParamsForExport(filters);
    return axios.get<ApiResponse<StateData>>(API.STATE, { params });
  },
  updateState(payload: StateData) {
    const { id, country_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<StateData>>(`${API.STATE}/${id}`, payloadWithoutId);
  },
  createState(payload: StateData) {
    return axios.post<ApiResponse<StateData>>(API.STATE, payload);
  },
  getStateForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<StateData>>(API.STATE, { params });
  },
};

export default stateApi;
