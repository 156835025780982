import { MRT_ColumnFiltersState, MRT_SortingState } from "material-react-table";
import { Filter } from "../../store/slices";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForExport } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import RequestData, { ApiResponse } from "./types";

const requestApi = {
  getRequest(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<RequestData>>(API.REQUEST, { params, headers: { noLoader: "true" } });
  },
  getRequestForExport(filters: {
    column: MRT_ColumnFiltersState;
    sorting: MRT_SortingState;
    user_type?: string;
    email?: string;
  }) {
    const params = generateParamsForExport(filters);
    return axios.get<ApiResponse<RequestData>>(API.REQUEST, { params });
  },
  getRequestById(payload: Filter) {
    const params = generateParams(payload);
    return axios.get<ApiResponse<RequestData>>(API.REQUEST, { params });
  },
  updateRequest(payload: RequestData) {
    const { id, country_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<RequestData>>(`${API.REQUEST}/${id}`, payloadWithoutId);
  },
  createRequest(payload: RequestData) {
    return axios.post<ApiResponse<RequestData>>(API.REQUEST, payload);
  },
};

export default requestApi;
