import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Button, Box } from "@mui/material";
import { requestApi } from "../../../api";
import { RequestData } from "../../../api/type";
import { CommonCheckboxField, CommonTextField, ContainerGrid } from "../../../components";
import { LABELS, ROUTE_PATHS, USER_ROLES } from "../../../utils/constants";
import { FIELD_NAMES } from "./typeAndSchema/constants";
import { baseValidationSchema } from "./typeAndSchema/validationSchema";
import { useAppSelector } from "../../../hooks";
import { RootState } from "../../../store";
import "../Requests.scss";

dayjs.extend(utc);

const ViewRequest = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [isDistributorCA, setIsDistributorCA] = useState<boolean>(false);
  const [requestIdFormData, setRequestIdFormData] = useState<RequestData | null>(null);
  const [validationSchema, setValidationSchema] = useState(baseValidationSchema);
  const { userDetails } = useAppSelector((state: RootState) => state.user);

  const formik = useFormik({
    initialValues: {
      [FIELD_NAMES.STATUS]: "",
      [FIELD_NAMES.REQUESTED_BY]: "",
      [FIELD_NAMES.REQUESTED_DATE]: "",
      [FIELD_NAMES.REQUESTED_ID]: "",
      [FIELD_NAMES.DISTRIBUTOR_ID]: "",
      [FIELD_NAMES.DISTRIBUTOR_LOCATION_ID]: "",
      [FIELD_NAMES.UNIT_OF_MEASUREMENT]: "LB",
      [FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME]: "",
      [FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME]: "",
      [FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID]: "",
      [FIELD_NAMES.DISTRIBUTOR_CUSTOMER]: "",
      [FIELD_NAMES.CUSTOMER_NUMBER]: "",
      [FIELD_NAMES.COUNTRY_ID]: "",
      [FIELD_NAMES.STATE_ID]: "",
      [FIELD_NAMES.CITY]: "",
      [FIELD_NAMES.SELLER_ID]: "",
      [FIELD_NAMES.MARKET_ID]: "",
      [FIELD_NAMES.APPLICATION]: "",
      [FIELD_NAMES.OEM_ID]: "",
      [FIELD_NAMES.OEM_NAME]: "",
      [FIELD_NAMES.COMPETITOR_ID]: "",
      [FIELD_NAMES.COMPETITOR_NAME]: "",
      [FIELD_NAMES.COMPETITOR_VOLUME]: "",
      [FIELD_NAMES.SALES_TEXT]: "",
      [FIELD_NAMES.PRODUCT_ID]: "",
      [FIELD_NAMES.PRODUCT_NAME]: "",
      [FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER]: "",
      [FIELD_NAMES.THIRD_PARTY]: false,
      [FIELD_NAMES.VALID_FROM]: "",
      [FIELD_NAMES.VALID_TO]: "",
      [FIELD_NAMES.MINIMUM_QUANTITY]: "",
      [FIELD_NAMES.LIST_PRICE]: "",
      [FIELD_NAMES.LIST_DISCOUNT]: "",
      [FIELD_NAMES.REQUESTED_PRICE]: "",
      [FIELD_NAMES.REQUESTED_DISCOUNT]: "",
      [FIELD_NAMES.INTO_STOCK_PRICE]: "",
      [FIELD_NAMES.SUPPORT_PRICE]: "",
      [FIELD_NAMES.ADJUSTMENT]: "",
      [FIELD_NAMES.COMMENT]: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const setFormikValues = (data: any) => {
    const formikValues = {
      [FIELD_NAMES.STATUS]: data.status ?? "",
      [FIELD_NAMES.REQUESTED_BY]: data.requested_by ?? "",
      [FIELD_NAMES.REQUESTED_DATE]: dayjs.utc(data.requested_date).format("MM/DD/YYYY"),
      [FIELD_NAMES.REQUESTED_ID]: data.id ?? "",
      [FIELD_NAMES.DISTRIBUTOR_ID]: data.distributor_name ?? "",
      [FIELD_NAMES.DISTRIBUTOR_LOCATION_ID]: data.location ?? "",
      [FIELD_NAMES.UNIT_OF_MEASUREMENT]: data.unit_of_measurement ?? "",
      [FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME]: data.distributor_seller_first_name ?? "",
      [FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME]: data.distributor_seller_last_name ?? "",
      [FIELD_NAMES.DISTRIBUTOR_CUSTOMER_ID]: data.distributor_customer_id ?? "",
      [FIELD_NAMES.DISTRIBUTOR_CUSTOMER]: data.distributor_customer ?? "",
      [FIELD_NAMES.CUSTOMER_NUMBER]: data.customer_number ?? "",
      [FIELD_NAMES.COUNTRY_ID]: data.country_name ?? "",
      [FIELD_NAMES.STATE_ID]: data.state_name ?? "",
      [FIELD_NAMES.CITY]: data.city ?? "",
      [FIELD_NAMES.SELLER_ID]: data.seller_name ?? "",
      [FIELD_NAMES.MARKET_ID]: data.market_name ?? "",
      [FIELD_NAMES.APPLICATION]: data.application ?? "",
      [FIELD_NAMES.OEM_ID]: data.oem_id ?? "",
      [FIELD_NAMES.OEM_NAME]: data.oem_name ?? "",
      [FIELD_NAMES.COMPETITOR_ID]: data.competitor_id ?? "",
      [FIELD_NAMES.COMPETITOR_NAME]: data.competitor_name ?? "",
      [FIELD_NAMES.COMPETITOR_VOLUME]: data.competitor_volume ?? "",
      [FIELD_NAMES.SALES_TEXT]: data.sales_text ?? "",
      [FIELD_NAMES.PRODUCT_ID]: data.product_id ?? "",
      [FIELD_NAMES.PRODUCT_NAME]: data.product_name ?? "",
      [FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER]: data.distributor_product_number ?? "",
      [FIELD_NAMES.THIRD_PARTY]: data.third_party || false,
      [FIELD_NAMES.VALID_FROM]: dayjs.utc(data.valid_from).format("MM/DD/YYYY"),
      [FIELD_NAMES.VALID_TO]: dayjs.utc(data.valid_to).format("MM/DD/YYYY"),
      [FIELD_NAMES.MINIMUM_QUANTITY]: data.minimum_quantity ?? "",
      [FIELD_NAMES.LIST_PRICE]: data.list_price ?? "",
      [FIELD_NAMES.LIST_DISCOUNT]: data.list_discount ?? "",
      [FIELD_NAMES.REQUESTED_PRICE]: data.requested_price ?? "",
      [FIELD_NAMES.REQUESTED_DISCOUNT]: data.requested_discount ?? "",
      [FIELD_NAMES.INTO_STOCK_PRICE]: data.into_stock_price ?? "",
      [FIELD_NAMES.SUPPORT_PRICE]: data.support_price ?? "",
      [FIELD_NAMES.ADJUSTMENT]: data.adjustment ?? "",
      [FIELD_NAMES.COMMENT]: data.comment ?? "",
    };
    formik.setValues(formikValues);
  };

  useEffect(() => {
    if (id) fetchRequestDetails(id);
  }, [id]);

  const fetchRequestDetails = async (id: string) => {
    try {
      const payload = { column: [{ id: "id", value: id }], sorting: [], pagination: { pageIndex: 0, pageSize: 1 } };
      const { data } = await requestApi.getRequestById(payload);
      const formData = data.data[0];
      if (userDetails.role === USER_ROLES.INTERNAL) {
        navigate(`/${ROUTE_PATHS.REQUESTS}`);
      } else {
        setRequestIdFormData(formData);
        setFormikValues(formData);
        setIsDistributorCA(formData.entity_name.toLowerCase() === "ca");
      }
    } catch (err) {
      console.error("Failed to fetch Request Data By ID:", err);
    }
  };

  return (
    <Box className="priceSupportRequestPageContainer">
      <form>
        <Box className="requestInfoWrapper">
          <ContainerGrid>
            <Grid size={12}>
              <Box className="headingWrapper">
                <h1 className="heading">Price Support Request</h1>
              </Box>
            </Grid>
          </ContainerGrid>
          <ContainerGrid>
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <Box sx={{ marginBottom: "25px" }}>
                <CommonTextField
                  name={FIELD_NAMES.REQUESTED_BY}
                  label={`${LABELS.REQUESTED_BY}*`}
                  formikProps={{
                    field: formik.getFieldProps(FIELD_NAMES.REQUESTED_BY),
                    meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_BY),
                  }}
                  variant="outlined"
                  disabled={true}
                />
              </Box>
              <Box sx={{ marginBottom: "25px" }}>
                <CommonTextField
                  name={FIELD_NAMES.REQUESTED_ID}
                  label={`${LABELS.REQUESTED_ID}*`}
                  formikProps={{
                    field: formik.getFieldProps(FIELD_NAMES.REQUESTED_ID),
                    meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_ID),
                  }}
                  variant="outlined"
                  disabled={true}
                />
              </Box>
              <Box sx={{ marginBottom: "25px" }}>
                <CommonTextField
                  name={FIELD_NAMES.REQUESTED_DATE}
                  label={`${LABELS.REQUESTED_DATE}*`}
                  formikProps={{
                    field: formik.getFieldProps(FIELD_NAMES.REQUESTED_DATE),
                    meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_DATE),
                  }}
                  variant="outlined"
                  disabled={true}
                />
              </Box>
              <Box sx={{ marginBottom: "25px" }}>
                <CommonTextField
                  name={FIELD_NAMES.STATUS}
                  label={`${LABELS.STATUS}*`}
                  formikProps={{
                    field: formik.getFieldProps(FIELD_NAMES.STATUS),
                    meta: formik.getFieldMeta(FIELD_NAMES.STATUS),
                  }}
                  variant="outlined"
                  disabled={true}
                />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <CommonTextField
                name={FIELD_NAMES.DISTRIBUTOR_ID}
                label={`${LABELS.DISTRIBUTOR} ${LABELS.NAME}*`}
                formikProps={{
                  field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_ID),
                  meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_ID),
                }}
                variant="outlined"
                disabled={true}
              />
              <Box className="distributorInfoWrap">
                <p>
                  {LABELS.SALES_ORGANISATION}: {requestIdFormData?.sales_orgnisation}
                </p>
                <p>
                  {LABELS.BUSINESS_ENTITY}: {requestIdFormData?.business_entity_id}
                </p>
                <p>
                  {LABELS.SOLD_NUMBER}: {requestIdFormData?.sold_to_number}
                </p>
              </Box>
            </Grid>
          </ContainerGrid>
        </Box>
        <Box className="requestFormWrapper">
          <ContainerGrid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.DISTRIBUTOR_LOCATION_ID}
                    label={`${LABELS.DISTRIBUTOR} ${LABELS.LOCATION}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_LOCATION_ID),
                      meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_LOCATION_ID),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.UNIT_OF_MEASUREMENT}
                    label={`${LABELS.UNIT_OF_MEASUREMENT}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.UNIT_OF_MEASUREMENT),
                      meta: formik.getFieldMeta(FIELD_NAMES.UNIT_OF_MEASUREMENT),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            <Grid size={12}>
              <p className="heading">Distributor Seller</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME}
                    label={`${LABELS.FIRST_NAME}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME),
                      meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_SELLER_FIRST_NAME),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME}
                    label={`${LABELS.LAST_NAME}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME),
                      meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_SELLER_LAST_NAME),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            <Grid size={12}>
              <p className="heading">Account/Marketing Information</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.DISTRIBUTOR_CUSTOMER}
                    label={`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER} ${LABELS.NAME}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_CUSTOMER),
                      meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_CUSTOMER),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.CUSTOMER_NUMBER}
                    label={`${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.CUSTOMER_NUMBER),
                      meta: formik.getFieldMeta(FIELD_NAMES.CUSTOMER_NUMBER),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <CommonTextField
                    name={FIELD_NAMES.COUNTRY_ID}
                    label={`${LABELS.COUNTRY}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.COUNTRY_ID),
                      meta: formik.getFieldMeta(FIELD_NAMES.COUNTRY_ID),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <CommonTextField
                    name={FIELD_NAMES.STATE_ID}
                    label={`${LABELS.STATE}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.STATE_ID),
                      meta: formik.getFieldMeta(FIELD_NAMES.STATE_ID),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                  <CommonTextField
                    name={FIELD_NAMES.CITY}
                    label={`${LABELS.CITY}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.CITY),
                      meta: formik.getFieldMeta(FIELD_NAMES.CITY),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.SELLER_ID}
                    label={`${LABELS.COVESTRO_SELLER} ${LABELS.NAME}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.SELLER_ID),
                      meta: formik.getFieldMeta(FIELD_NAMES.SELLER_ID),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.MARKET_ID}
                    label={`${LABELS.MARKET_NAME}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.MARKET_ID),
                      meta: formik.getFieldMeta(FIELD_NAMES.MARKET_ID),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={12}>
                  <CommonTextField
                    name={FIELD_NAMES.APPLICATION}
                    label={`${LABELS.APPLICATION}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.APPLICATION),
                      meta: formik.getFieldMeta(FIELD_NAMES.APPLICATION),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            {!isDistributorCA && (
              <>
                <Grid size={12}>
                  <p className="heading">OEM</p>
                </Grid>
                <Grid size={12}>
                  <ContainerGrid disableGutters={true}>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                      <CommonTextField
                        name={FIELD_NAMES.OEM_NAME}
                        label={`${LABELS.OEM_NAME}*`}
                        formikProps={{
                          field: formik.getFieldProps(FIELD_NAMES.OEM_NAME),
                          meta: formik.getFieldMeta(FIELD_NAMES.OEM_NAME),
                        }}
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                      <CommonCheckboxField
                        name={FIELD_NAMES.THIRD_PARTY}
                        label={`${LABELS.THIRD_PARTY_CONTRACT}`}
                        formikProps={{
                          field: formik.getFieldProps(FIELD_NAMES.THIRD_PARTY),
                          meta: formik.getFieldMeta(FIELD_NAMES.THIRD_PARTY),
                        }}
                        disabled={true}
                      />
                    </Grid>
                  </ContainerGrid>
                </Grid>
              </>
            )}
            <Grid size={12}>
              <p className="heading">Covestro Competitor Information</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.COMPETITOR_NAME}
                    label={`${LABELS.COMPETITOR_NAME}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.COMPETITOR_NAME),
                      meta: formik.getFieldMeta(FIELD_NAMES.COMPETITOR_NAME),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.COMPETITOR_VOLUME}
                    label={`${LABELS.COMPETITOR_VOLUME_YEAR}`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.COMPETITOR_VOLUME),
                      meta: formik.getFieldMeta(FIELD_NAMES.COMPETITOR_VOLUME),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            <Grid size={12}>
              <p className="heading">Product Information</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.SALES_TEXT}
                    label={`${LABELS.PRODUCT_SALES_TEXT}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.SALES_TEXT),
                      meta: formik.getFieldMeta(FIELD_NAMES.SALES_TEXT),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.PRODUCT_ID}
                    label={`${LABELS.COVESTRO_PRODUCT_NUMBER}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.PRODUCT_ID),
                      meta: formik.getFieldMeta(FIELD_NAMES.PRODUCT_ID),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={12}>
                  <CommonTextField
                    name={FIELD_NAMES.PRODUCT_NAME}
                    label={`${LABELS.COVESTRO_PRODUCT}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.PRODUCT_NAME),
                      meta: formik.getFieldMeta(FIELD_NAMES.PRODUCT_NAME),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={12}>
                  <CommonTextField
                    name={FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER}
                    label={`${LABELS.DISTRIBUTOR_PRODUCT_NUMBER}`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER),
                      meta: formik.getFieldMeta(FIELD_NAMES.DISTRIBUTOR_PRODUCT_NUMBER),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            <Grid size={12}>
              <p className="heading">Covestro Special Pricing Information</p>
            </Grid>
            <Grid size={12}>
              <ContainerGrid disableGutters={true}>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.VALID_FROM}
                    label={`${LABELS.EFFECTIVE_DATE}`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.VALID_FROM),
                      meta: formik.getFieldMeta(FIELD_NAMES.VALID_FROM),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                  <CommonTextField
                    name={FIELD_NAMES.VALID_TO}
                    label={`${LABELS.EXPIRATION_DATE}`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.VALID_TO),
                      meta: formik.getFieldMeta(FIELD_NAMES.VALID_TO),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid size={12}>
                  <CommonTextField
                    name={FIELD_NAMES.MINIMUM_QUANTITY}
                    label={`${LABELS.MINIMUM_QTY_PURCHASE}*`}
                    formikProps={{
                      field: formik.getFieldProps(FIELD_NAMES.MINIMUM_QUANTITY),
                      meta: formik.getFieldMeta(FIELD_NAMES.MINIMUM_QUANTITY),
                    }}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
              </ContainerGrid>
            </Grid>
            {isDistributorCA && (
              <Grid size={12}>
                <ContainerGrid disableGutters={true}>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                    <CommonTextField
                      name={FIELD_NAMES.INTO_STOCK_PRICE}
                      label={`${LABELS.INTO_STOCK_PRICE}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.INTO_STOCK_PRICE),
                        meta: formik.getFieldMeta(FIELD_NAMES.INTO_STOCK_PRICE),
                      }}
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
                    <CommonTextField
                      name={FIELD_NAMES.SUPPORT_PRICE}
                      label={`${LABELS.SUPPORT_PRICE}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.SUPPORT_PRICE),
                        meta: formik.getFieldMeta(FIELD_NAMES.SUPPORT_PRICE),
                      }}
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                </ContainerGrid>
              </Grid>
            )}
            {!isDistributorCA && (
              <Grid size={12}>
                <ContainerGrid disableGutters={true}>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }}>
                    <span>Into Stock Price</span>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                    <CommonTextField
                      name={FIELD_NAMES.LIST_PRICE}
                      label={`${LABELS.LIST_PRICE}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.LIST_PRICE),
                        meta: formik.getFieldMeta(FIELD_NAMES.LIST_PRICE),
                      }}
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                    <CommonTextField
                      name={FIELD_NAMES.LIST_DISCOUNT}
                      label={`${LABELS.DISCOUNT}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.LIST_DISCOUNT),
                        meta: formik.getFieldMeta(FIELD_NAMES.LIST_DISCOUNT),
                      }}
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }}>
                    <span>Supported Price</span>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                    <CommonTextField
                      name={FIELD_NAMES.REQUESTED_PRICE}
                      label={`${LABELS.REQUESTED}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.REQUESTED_PRICE),
                        meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_PRICE),
                      }}
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 5 }}>
                    <CommonTextField
                      name={FIELD_NAMES.REQUESTED_DISCOUNT}
                      label={`${LABELS.DISCOUNT}*`}
                      formikProps={{
                        field: formik.getFieldProps(FIELD_NAMES.REQUESTED_DISCOUNT),
                        meta: formik.getFieldMeta(FIELD_NAMES.REQUESTED_DISCOUNT),
                      }}
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>
                </ContainerGrid>
              </Grid>
            )}
            <Grid size={12}>
              <CommonTextField
                name={FIELD_NAMES.ADJUSTMENT}
                label={`${LABELS.ADJUSTMENT}*`}
                formikProps={{
                  field: formik.getFieldProps(FIELD_NAMES.ADJUSTMENT),
                  meta: formik.getFieldMeta(FIELD_NAMES.ADJUSTMENT),
                }}
                variant="outlined"
                disabled={true}
              />
            </Grid>
            <Grid size={12} sx={{ display: "none" }}>
              <CommonTextField
                name={FIELD_NAMES.COMMENT}
                label={`${LABELS.COMMENT}`}
                formikProps={{
                  field: formik.getFieldProps(FIELD_NAMES.COMMENT),
                  meta: formik.getFieldMeta(FIELD_NAMES.COMMENT),
                }}
                variant="outlined"
                multiline
                rows={4}
                disabled={true}
              />
            </Grid>
            <Grid size={12} className="actionButtonsWrapper">
              <ContainerGrid disableGutters={true}>
                <Grid size={12}>
                  <Box className="actionBtnWrap">
                    <Button onClick={() => navigate(`/${ROUTE_PATHS.REQUESTS}`)} variant="contained" color="primary">
                      Close
                    </Button>
                  </Box>
                </Grid>
              </ContainerGrid>
            </Grid>
          </ContainerGrid>
        </Box>
      </form>
    </Box>
  );
};

export default ViewRequest;
