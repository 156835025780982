import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
} from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NAV_LINKS, USER_ROLES } from "../../../utils/constants";
import { useNavigateWithReset } from "../../../hooks";
import Images from "../../../assets/images";

interface MobileMenuContentProps {
  toggleMobileMenu: () => void;
  handleLogout: () => void;
  currentRole: string;
}

const MobileMenuContent = ({ toggleMobileMenu, handleLogout, currentRole }: MobileMenuContentProps) => {
  const navigateWithReset = useNavigateWithReset();

  return (
    <Box onClick={toggleMobileMenu} sx={{ textAlign: "center" }} className="drawerMenuWrapper">
      <Box className="logoWrap" sx={{ display: "flex" }} onClick={() => navigateWithReset("/")}>
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <img src={Images.HeaderLogo} alt="CUPS Logo" className="mainLogo" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <List>
        {NAV_LINKS.map((navItem) => (
          <Fragment key={navItem.title}>
            {!navItem.subLinks && (
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <Link
                    onClick={() => navigateWithReset(navItem.path)}
                    sx={{ "&.active": { color: "#4cbcec" } }}
                    className="sideNavLink">
                    {navItem.title}
                  </Link>
                </ListItemButton>
              </ListItem>
            )}
            {currentRole === USER_ROLES.INTERNAL && navItem.subLinks && (
              <>
                <Accordion onClick={(e) => e.stopPropagation()} className="sideNavAccordionWrap">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{navItem.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {navItem.subLinks.map((subNavItem) => (
                      <ListItem key={subNavItem.title} disablePadding>
                        <ListItemButton sx={{ textAlign: "center" }} onClick={toggleMobileMenu}>
                          <Link
                            onClick={() => navigateWithReset(subNavItem.path)}
                            sx={{ "&.active": { color: "#4cbcec" } }}
                            className="sideNavLink">
                            {subNavItem.title}
                          </Link>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Fragment>
        ))}
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link onClick={handleLogout} className="sideNavLink logout">
              Logout
            </Link>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default MobileMenuContent;
