import { useState, useEffect } from "react";
import { AddOrEditDistributorCustomerModal, CommonPageLayout } from "../../../components";
import { distributorCustomerApi } from "../../../api";
import { DistributorCustomerData } from "../../../api/type";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn, exportDataToExcel } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { LABELS, USER_ROLES } from "../../../utils/constants";
import { ExportToExcel, setFilters } from "../../../store/slices";
import { RootState } from "../../../store";

const columns: MRT_ColumnDef<DistributorCustomerData>[] = [
  {
    accessorKey: "distributor_customer",
    header: `${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER}  ${LABELS.NAME}`,
    size: 250,
  },
  { accessorKey: "customer_number", header: `${LABELS.DISTRIBUTOR} ${LABELS.CUSTOMER_NUMBER}`, size: 200 },
  { accessorKey: "distributor_name", header: `${LABELS.DISTRIBUTOR} ${LABELS.NAME}` },
  { accessorKey: "country_name", header: LABELS.COUNTRY },
  { accessorKey: "state_name", header: LABELS.STATE },
  { accessorKey: "city", header: LABELS.CITY },
  createBooleanColumn<DistributorCustomerData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const DistributorCustomer = () => {
  const dispatch = useAppDispatch();

  const { filters, isResetting } = useAppSelector((state: RootState) => state.dataTable);
  const { userDetails } = useAppSelector((state: RootState) => state.user);

  const [dataTable, setDataTable] = useState<DistributorCustomerData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [hasInitialFiltersBeenApplied, setHasInitialFiltersBeenApplied] = useState<Boolean>(false);

  useEffect(() => {
    dispatch(setFilters({ column: initialColumnFilter }));
    setHasInitialFiltersBeenApplied(true);
  }, [dispatch]);

  useEffect(() => {
    if (hasInitialFiltersBeenApplied && !isResetting) {
      fetchData();
    }
  }, [filters, hasInitialFiltersBeenApplied]);

  const fetchData = async () => {
    try {
      let updatedFilters = filters;
      if (userDetails.role === USER_ROLES.EXTERNAL) {
        updatedFilters = {
          ...filters,
          user_type: "external",
          email: userDetails.email,
          // email: "usman@test.com",
        };
      }
      const { data } = await distributorCustomerApi.getDistributorCustomer(updatedFilters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleExportToExcel = async () => {
    try {
      const allData = await fetchDataForExport();
      if (!allData || allData.length === 0) return;
      exportDataToExcel(allData, columns, "Distributor Customer");
    } catch (err) {
      console.error("Failed to export data:", err);
    }
  };

  const fetchDataForExport = async () => {
    try {
      const { column, sorting } = filters;
      const exportFilters: ExportToExcel = { column, sorting };
      const { data } = await distributorCustomerApi.getDistributorCustomerForExport(exportFilters);
      return data.data;
    } catch (err) {
      console.error("Failed to fetch data for export:", err);
      throw err;
    }
  };

  const handleSubmit = async (formData: DistributorCustomerData) => {
    try {
      if (formData.id) await distributorCustomerApi.updateDistributorCustomer(formData);
      else await distributorCustomerApi.createDistributorCustomer(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Distributor Customer"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditDistributorCustomerModal}
      handleSubmit={handleSubmit}
      handleExportToExcel={handleExportToExcel}
    />
  );
};

export default DistributorCustomer;
