import { useState, useEffect } from "react";
import { AddOrEditCompetitorModal, CommonPageLayout } from "../../../components";
import { competitorApi } from "../../../api";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn, exportDataToExcel } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CompetitorData } from "../../../api/type";
import { LABELS } from "../../../utils/constants";
import { ExportToExcel, setFilters } from "../../../store/slices";
import { RootState } from "../../../store";

const columns: MRT_ColumnDef<CompetitorData>[] = [
  { accessorKey: "competitor_name", header: LABELS.COMPETITOR_NAME },
  { accessorKey: "distributor_name", header: `${LABELS.DISTRIBUTOR} ${LABELS.NAME}` },
  createBooleanColumn<CompetitorData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const Competitor = () => {
  const dispatch = useAppDispatch();

  const { filters, isResetting } = useAppSelector((state: RootState) => state.dataTable);

  const [dataTable, setDataTable] = useState<CompetitorData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [hasInitialFiltersBeenApplied, setHasInitialFiltersBeenApplied] = useState<Boolean>(false);

  useEffect(() => {
    dispatch(setFilters({ column: initialColumnFilter }));
    setHasInitialFiltersBeenApplied(true);
  }, [dispatch]);

  useEffect(() => {
    if (hasInitialFiltersBeenApplied && !isResetting) {
      fetchData();
    }
  }, [filters, hasInitialFiltersBeenApplied]);

  const fetchData = async () => {
    try {
      const { data } = await competitorApi.getCompetitor(filters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleExportToExcel = async () => {
    try {
      const allData = await fetchDataForExport();
      if (!allData || allData.length === 0) return;
      exportDataToExcel(allData, columns, "Competitor");
    } catch (err) {
      console.error("Failed to export data:", err);
    }
  };

  const fetchDataForExport = async () => {
    try {
      const { column, sorting } = filters;
      const exportFilters: ExportToExcel = { column, sorting };
      const { data } = await competitorApi.getCompetitorForExport(exportFilters);
      return data.data;
    } catch (err) {
      console.error("Failed to fetch data for export:", err);
      throw err;
    }
  };

  const handleSubmit = async (formData: CompetitorData) => {
    try {
      if (formData.id) await competitorApi.updateCompetitor(formData);
      else await competitorApi.createCompetitor(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Competitor"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditCompetitorModal}
      handleSubmit={handleSubmit}
      handleExportToExcel={handleExportToExcel}
    />
  );
};

export default Competitor;
