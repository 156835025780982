import { useState, useEffect, useRef, useMemo } from "react";
import Grid from "@mui/material/Grid2";
import { Box, Button, FormControl, InputLabel, ListItemIcon, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Edit, FileCopy, FileDownload, FilterListOff, Save as SaveIcon } from "@mui/icons-material";
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnSizingState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  MRT_VisibilityState,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useNavigate } from "react-router-dom";
import { OnChangeFn } from "@tanstack/react-table";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { LABELS, MESSAGES as msg, ROUTE_PATHS } from "../../../utils/constants";
import { PageTitle, ContainerGrid, AddOrUpdateSavedFiltersModal } from "../../../components";
import { FieldStatusData, RequestData, SavedFilterData } from "../../../api/type";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { requestApi, savedFilterApi, fieldStatusApi } from "../../../api";
import { MODAL_ACTION_ADD, showModal, showAlert } from "../../../store/slices";
import { formatDateColumnsInFilters, generateFileName } from "../../../utils/helpers";
import { NON_OEM_LAYOUT_COLUMNS, OEM_LAYOUT_COLUMNS, initialSortingFilter } from "../contant";
import { RootState } from "../../../store";

const InternalUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isInitialRender = useRef(true);

  const {
    userDetails: { email },
  } = useAppSelector((state: RootState) => state.user);

  const INITIAL_FILTERS = {
    column: [],
    sorting: initialSortingFilter,
    pagination: { pageIndex: 0, pageSize: 10 },
  };

  const [tableFilter, setTableFilter] = useState<{
    column: MRT_ColumnFiltersState;
    sorting: MRT_SortingState;
    pagination: MRT_PaginationState;
  }>(INITIAL_FILTERS);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [columns, setColumns] = useState<MRT_ColumnDef<RequestData>[]>(OEM_LAYOUT_COLUMNS);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>({});
  const [columnSizingUI, setColumnSizingUI] = useState<MRT_ColumnSizingState>({});
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataTable, setDataTable] = useState<RequestData[]>([]);
  const columnVisibilityRef = useRef(columnVisibility);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const columnResizeTimeout = useRef<NodeJS.Timeout | null>(null);

  const [savedFiltersDropdown, setSavedFiltersDropdown] = useState<SavedFilterData[]>([]);
  const [isSavedFiltersDropdownLoading, setIsSavedFiltersDropdownLoading] = useState<boolean>(false);
  const [hasFetchedSavedFilter, setHasFetchedSavedFilter] = useState<boolean>(false);
  const [activeSavedFilter, setActiveSavedFilter] = useState<string>("");
  const [isShowSavedFiltersModal, setIsShowSavedFiltersModal] = useState<boolean>(false);

  const [fieldStatus, setFieldStatus] = useState<FieldStatusData>();
  const [isFieldStatusLoaded, setIsFieldStatusLoaded] = useState<boolean>(false);

  const [isOemLayoutEnabled, setIsOemLayoutEnabled] = useState<boolean>(true);
  const [isDropdownChange, setIsDropdownChange] = useState<string>("");

  const [isShowProgressBars, setIsShowProgressBars] = useState<boolean>(false);

  const [isError, setIsError] = useState(false);

  const navigateToRequestDetail = (id: string, actionType: string) => {
    navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}/${id}?type=${actionType}`);
  };

  const applySelectedSavedFilter = (event: SelectChangeEvent) => {
    const selectedFilterId = event.target.value;
    setActiveSavedFilter(selectedFilterId);
    const selectedFilter = savedFiltersDropdown.find((filter) => filter.id === Number(selectedFilterId));
    if (selectedFilter) {
      const filterColumns = formatDateColumnsInFilters(selectedFilter.filter.savedColumns);
      setTableFilter((prev) => ({ ...prev, column: filterColumns, pagination: selectedFilter.filter.savedPagination }));
    } else {
      setTableFilter(INITIAL_FILTERS);
    }
  };

  const loadSavedFiltersOnDropdownOpen = () => {
    if (!hasFetchedSavedFilter) {
      setIsSavedFiltersDropdownLoading(true);
      fetchSavedFilter();
    }
  };

  const resetState = () => {
    setIsShowProgressBars(true);
    setSavedFiltersDropdown([]);
    setActiveSavedFilter("");
    setHasFetchedSavedFilter(false);
    setTableFilter(INITIAL_FILTERS);
    setIsFieldStatusLoaded(false);
    fetchSavedFieldStatus();
  };

  const saveFieldStatus = async () => {
    const payload = {
      field: { columnVisibility, columnOrder, columnSizing },
      user_email: email,
    };
    try {
      if (fieldStatus?.id) await fieldStatusApi.updateFieldStatus({ ...payload, id: fieldStatus?.id });
      else await fieldStatusApi.createFieldStatus(payload);
    } catch (error) {
      console.error("Failed to save field status:", error);
    }
  };

  const saveFilter = async (formData: { id?: number; filter_name?: string }) => {
    // Check if there are any tableFilter to save
    if (!tableFilter.column.length) {
      dispatch(showAlert({ message: msg.NO_FILTER_TO_SAVE, alertType: "warning" }));
      return;
    }

    const payload = {
      filter: {
        oem_layout: isOemLayoutEnabled,
        savedColumns: tableFilter.column,
        savedPagination: {
          pageIndex: tableFilter.pagination.pageIndex,
          pageSize: tableFilter.pagination.pageSize,
        },
      },
      user_email: email,
    };

    try {
      if (formData.id) {
        await savedFilterApi.updateSavedFilter({ ...payload, id: formData.id });
      } else if (formData.filter_name) {
        await savedFilterApi.createSavedFilter({ ...payload, filter_name: formData.filter_name });
      }
      setHasFetchedSavedFilter(false);
    } catch (err) {
      console.error("Failed to save filter:", err);
    }
  };

  const fetchSavedFieldStatus = async () => {
    try {
      const response = await fieldStatusApi.getFieldStatus(email);
      const savedData = response.data.data;
      if (savedData.length) {
        const savedFieldStatus = savedData[0];
        setFieldStatus(savedFieldStatus);
        if (savedFieldStatus.field.columnVisibility) {
          setColumnVisibility(savedFieldStatus.field.columnVisibility);
        }
        if (savedFieldStatus.field.columnOrder) {
          setColumnOrder(savedFieldStatus.field.columnOrder);
        }
        if (savedFieldStatus.field.columnSizing) {
          setColumnSizing(savedFieldStatus.field.columnSizing);
          setColumnSizingUI(savedFieldStatus.field.columnSizing);
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsFieldStatusLoaded(true);
    }
  };

  const fetchSavedFilter = async () => {
    try {
      const { data } = await savedFilterApi.getSavedFilter(email);
      // Filter the saved tableFilter based on OEM layout
      const filteredSavedFilters = filterByOemLayout(data.data, isOemLayoutEnabled);
      setSavedFiltersDropdown(filteredSavedFilters);
      setHasFetchedSavedFilter(true);
      setIsSavedFiltersDropdownLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchRequest = async () => {
    try {
      const updatedFilters = {
        ...tableFilter,
        column: [...tableFilter.column, { id: "is_ep", value: isOemLayoutEnabled }],
      };
      const columnsToSet = isOemLayoutEnabled ? OEM_LAYOUT_COLUMNS : NON_OEM_LAYOUT_COLUMNS;
      setColumns(columnsToSet);
      const { data } = await requestApi.getRequest(updatedFilters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsShowProgressBars(false);
    }
  };

  const filterByOemLayout = (tableFilter: SavedFilterData[], isEnabled: boolean) => {
    return tableFilter.filter((filter) => filter.filter.oem_layout === isEnabled);
  };

  useEffect(() => {
    fetchSavedFieldStatus();
  }, []);

  useEffect(() => {
    if (!isInitialRender.current) {
      const storeInLocal = { isOemLayoutEnabled, tableFilter };
      localStorage.setItem("requestTable", JSON.stringify(storeInLocal));
      setIsShowProgressBars(true);
      fetchRequest();
    }
  }, [tableFilter]);

  useEffect(() => {
    if (!isInitialRender.current) resetState();
  }, [isDropdownChange]);

  useEffect(() => {
    const storedRequestTable = localStorage.getItem("requestTable");

    if (!storedRequestTable) return;

    const parsedRequestTable = JSON.parse(storedRequestTable);
    setIsOemLayoutEnabled(parsedRequestTable.isOemLayoutEnabled);

    const formattedColumns = formatDateColumnsInFilters(parsedRequestTable.tableFilter.column);
    const updatedTableFilter = {
      ...parsedRequestTable.tableFilter,
      column: formattedColumns,
    };

    setTableFilter(updatedTableFilter);
  }, []);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  useEffect(() => {
    if (
      isFieldStatusLoaded &&
      columnVisibility &&
      Object.keys(columnVisibility).length > 0 &&
      columnOrder &&
      columnOrder.length > 0 &&
      columnSizing &&
      Object.keys(columnSizing).length > 0
    ) {
      saveFieldStatus();
    }
  }, [columnVisibility, columnOrder, columnSizing, isFieldStatusLoaded]);

  useEffect(() => {
    if (columns && columns.length > 0 && Object.keys(columnVisibility).length === 0) {
      const initialVisibility = columns.reduce((acc, column) => {
        const key = column.accessorKey || column.id;
        if (key) acc[key] = true;
        return acc;
      }, {} as MRT_VisibilityState);
      setColumnVisibility(initialVisibility);
    }
  }, [columns]);

  const handleColumnFiltersChange: OnChangeFn<MRT_ColumnFiltersState> = (updaterOrValue) => {
    setTableFilter((prev) => ({
      ...prev,
      column: typeof updaterOrValue === "function" ? updaterOrValue(prev.column) : updaterOrValue,
    }));
  };

  const handlePaginationChange: OnChangeFn<MRT_PaginationState> = (updaterOrValue) => {
    setTableFilter((prev) => ({
      ...prev,
      pagination: typeof updaterOrValue === "function" ? updaterOrValue(prev.pagination) : updaterOrValue,
    }));
  };

  const handleSortingChange: OnChangeFn<MRT_SortingState> = (updaterOrValue) => {
    setTableFilter((prev) => ({
      ...prev,
      sorting: typeof updaterOrValue === "function" ? updaterOrValue(prev.sorting) : updaterOrValue,
    }));
  };

  const handleColumnVisibilityChange: OnChangeFn<MRT_VisibilityState> = (updaterOrValue) => {
    const updatedVisibility = typeof updaterOrValue === "function" ? updaterOrValue(columnVisibility) : updaterOrValue;
    setColumnVisibility(updatedVisibility);
  };

  const handleColumnOrderChange: OnChangeFn<MRT_ColumnOrderState> = (updaterOrValue) => {
    const updatedOrder = typeof updaterOrValue === "function" ? updaterOrValue(columnOrder) : updaterOrValue;
    setColumnOrder(updatedOrder);
  };

  const handleColumnSizingChange: OnChangeFn<MRT_ColumnSizingState> = (updaterOrValue) => {
    const updatedOrder = typeof updaterOrValue === "function" ? updaterOrValue(columnSizing) : updaterOrValue;
    setColumnSizingUI(updatedOrder);
    if (columnResizeTimeout.current) {
      clearTimeout(columnResizeTimeout.current);
    }
    columnResizeTimeout.current = setTimeout(() => {
      setColumnSizing(updatedOrder);
    }, 500);
  };

  useEffect(() => {
    columnVisibilityRef.current = columnVisibility;
  }, [columnVisibility]);

  const handleHideAllClick = () => {
    const updatedVisibility = Object.keys(columnVisibilityRef.current).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as MRT_VisibilityState);
    setColumnVisibility(updatedVisibility);
  };

  const handleShowAllClick = () => {
    const updatedVisibility = Object.keys(columnVisibilityRef.current).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {} as MRT_VisibilityState);
    setColumnVisibility(updatedVisibility);
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const buttons = Array.from(document.querySelectorAll<HTMLButtonElement>("button.MuiButtonBase-root"));
      buttons.forEach((button) => {
        if (button.textContent?.trim() === "Hide all") {
          button.removeEventListener("click", handleHideAllClick);
          button.addEventListener("click", handleHideAllClick);
        }
        if (button.textContent?.trim() === "Show all") {
          button.removeEventListener("click", handleShowAllClick);
          button.addEventListener("click", handleShowAllClick);
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  const fetchRequestForExport = async () => {
    try {
      const { pagination, ...restFilters } = tableFilter;
      const updatedFilters = {
        ...restFilters,
        column: [...restFilters.column, { id: "is_ep", value: isOemLayoutEnabled }],
      };
      const { data } = await requestApi.getRequestForExport(updatedFilters);
      return data;
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleExportToExcel = async () => {
    try {
      const dataTable = await fetchRequestForExport();
      if (dataTable && dataTable?.data.length > 0) {
        const formatValue = (key: string, value: any) => {
          // Format date values if needed
          if (typeof value === "string" && value.includes("T") && dayjs(value).isValid()) {
            return dayjs.utc(value).format("MM/DD/YYYY");
          }
          return value;
        };

        const mapRowToFormattedData = (row: any) => {
          const formattedRow = columns.reduce((acc, col) => {
            const key = col.accessorKey;
            const header = col.header;

            if (key) {
              acc[header] = formatValue(key, row[key as keyof typeof row]);
            }
            return acc;
          }, {} as Record<string, any>);

          if (row.market_name) formattedRow[LABELS.MARKET_NAME] = row.market_name;
          if (row.application) formattedRow[LABELS.APPLICATION] = row.application;
          if (row.competitor_name) formattedRow[LABELS.COMPETITOR_NAME] = row.competitor_name;
          if (row.requested_by) formattedRow[LABELS.REQUESTED_BY] = row.requested_by;
          if (row.distributor_seller_first_name || row.distributor_seller_last_name) {
            formattedRow["Distributor Seller Name"] = `${row.distributor_seller_first_name ?? ""} ${
              row.distributor_seller_last_name ?? ""
            }`.trim();
          }
          return formattedRow;
        };

        const formattedData = dataTable?.data.map(mapRowToFormattedData);
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const fileName = generateFileName("Request");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      }
    } catch (error) {
      console.error("Failed to export to Excel:", error);
    }
  };

  const table = useMaterialReactTable({
    columns: memoizedColumns,
    data: dataTable,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    layoutMode: "grid",
    enableStickyHeader: true,
    enableFacetedValues: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableRowActions: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    displayColumnDefOptions: { "mrt-row-actions": { size: 80, grow: false } },
    initialState: {
      density: "compact",
      showColumnFilters: true,
      columnPinning: { right: ["mrt-row-actions"] },
    },
    positionToolbarAlertBanner: "none",
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError ? { color: "error", children: "Error loading data" } : undefined,
    onColumnFiltersChange: handleColumnFiltersChange,
    onPaginationChange: handlePaginationChange,
    onSortingChange: handleSortingChange,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnSizingChange: handleColumnSizingChange,
    onRowSelectionChange: setRowSelection,
    rowCount: totalRows,
    state: {
      columnFilters: tableFilter.column,
      sorting: tableFilter.sorting,
      pagination: tableFilter.pagination,
      columnVisibility,
      columnOrder,
      columnSizing: columnSizingUI,
      rowSelection,
      showAlertBanner: isError,
      showProgressBars: isShowProgressBars,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
      onDoubleClick: () => navigateToRequestDetail(row.original.id, LABELS.EDIT),
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box>
          <Button onClick={handleExportToExcel} startIcon={<FileDownload />}>
            Export
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => {
              if (tableFilter.column.length) {
                setTableFilter((prev) => ({ ...prev, column: [] }));
              }
            }}
            startIcon={<FilterListOff />}>
            Clear All
          </Button>
        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        <MenuItem
          key={0}
          onClick={() => {
            navigateToRequestDetail(row.original.id, LABELS.CLONE);
            closeMenu();
          }}
          sx={{ m: 0, color: "#4cbcec" }}>
          <ListItemIcon>
            <FileCopy sx={{ color: "#4cbcec" }} />
          </ListItemIcon>
          Clone
        </MenuItem>,
        <MenuItem
          key={1}
          onClick={() => {
            navigateToRequestDetail(row.original.id, LABELS.EDIT);
            closeMenu();
          }}
          sx={{ m: 0, color: "#2e7d32" }}>
          <ListItemIcon>
            <Edit sx={{ color: "#2e7d32" }} />
          </ListItemIcon>
          Edit
        </MenuItem>,
      ].filter(Boolean);
    },
    muiTablePaperProps: () => ({ className: "DataTableComponentContainer" }),
  });

  return (
    <Box>
      <PageTitle
        title="Request"
        handleBtnNew={() => navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}?type=${LABELS.CREATE}`)}
      />
      <ContainerGrid sx={{ justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }}>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <FormControl fullWidth>
              <InputLabel>Select OEM Layout</InputLabel>
              <Select
                value={isOemLayoutEnabled}
                label="Select OEM Layout"
                onChange={(e) => {
                  setIsDropdownChange(e.target.value.toString());
                  setIsOemLayoutEnabled(e.target.value === "true");
                }}>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <Button
                variant="outlined"
                startIcon={<SaveIcon />}
                sx={{ mr: 2, padding: "15px" }}
                onClick={() => {
                  setIsShowSavedFiltersModal(true);
                  dispatch(showModal({ type: MODAL_ACTION_ADD }));
                }}>
                Save Filters
              </Button>
            </Box>
            <Box>
              <FormControl sx={{ minWidth: 160 }}>
                <InputLabel>Apply Saved Filter</InputLabel>
                <Select
                  value={activeSavedFilter}
                  label="Apply Saved Filter"
                  onChange={applySelectedSavedFilter}
                  onOpen={loadSavedFiltersOnDropdownOpen}>
                  {isSavedFiltersDropdownLoading ? (
                    <MenuItem disabled>
                      <em>Loading...</em>
                    </MenuItem>
                  ) : (
                    [
                      <MenuItem key="none" value="">
                        <em>None</em>
                      </MenuItem>,
                      ...savedFiltersDropdown.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.filter_name}
                        </MenuItem>
                      )),
                    ]
                  )}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Grid>
      </ContainerGrid>
      <ContainerGrid>
        <Grid size={12}>
          <MaterialReactTable table={table} />
        </Grid>
      </ContainerGrid>
      {isShowSavedFiltersModal && <AddOrUpdateSavedFiltersModal handleSubmit={saveFilter} />}
    </Box>
  );
};

export default InternalUser;
