import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_RowData,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_RowSelectionState,
} from "material-react-table";
import { Box, Button, ListItemIcon, MenuItem } from "@mui/material";
import { Edit, FileDownload, FilterListOff } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFilters, triggerRowAction } from "../../store/slices";
import { RootState } from "../../store";
import "./DataTable.scss";

interface CommonTableProps<T extends MRT_RowData> {
  data: T[];
  totalRows: number;
  columns: MRT_ColumnDef<T>[];
  exportFileName: string;
  handleExportToExcel: () => void;
}

const DataTable = <T extends MRT_RowData>({
  data,
  totalRows,
  columns,
  exportFileName,
  handleExportToExcel,
}: CommonTableProps<T>) => {
  const dispatch = useAppDispatch();
  const memoizedColumns = useMemo(() => columns, [columns]);
  const { filters, isRefetching } = useAppSelector((state: RootState) => state.dataTable);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(filters.column);
  const [sorting, setSorting] = useState<MRT_SortingState>(filters.sorting);
  const [pagination, setPagination] = useState<MRT_PaginationState>(filters.pagination);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (filters.column.length > 0 || filters.sorting.length > 0) {
      setColumnFilters(filters.column);
      setSorting(filters.sorting);
      setPagination(filters.pagination);
    }
  }, [filters]);

  useEffect(() => {
    const payload = {
      column: columnFilters,
      pagination,
      sorting,
    };
    dispatch(setFilters(payload));
  }, [columnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  const table = useMaterialReactTable({
    columns: memoizedColumns,
    data,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    layoutMode: "grid",
    enableStickyHeader: true,
    enableFacetedValues: true,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableRowActions: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    displayColumnDefOptions: { "mrt-row-actions": { size: 80, grow: false } },
    initialState: {
      density: "compact",
      showColumnFilters: true,
      columnPinning: { right: ["mrt-row-actions"] },
    },
    positionToolbarAlertBanner: "none",
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError ? { color: "error", children: "Error loading data" } : undefined,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    rowCount: totalRows,
    state: {
      rowSelection,
      columnFilters,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => setRowSelection((prev) => ({ [row.id]: !prev[row.id] })),
      onDoubleClick: () => dispatch(triggerRowAction({ data: row.original })),
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box>
          <Button onClick={handleExportToExcel} startIcon={<FileDownload />}>
            Export
          </Button>
        </Box>
        <Box>
          <Button onClick={() => columnFilters.length && setColumnFilters([])} startIcon={<FilterListOff />}>
            Clear All
          </Button>
        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        <MenuItem
          key={1}
          onClick={() => {
            dispatch(triggerRowAction({ data: row.original }));
            closeMenu();
          }}
          sx={{ m: 0, color: "#2e7d32" }}>
          <ListItemIcon>
            <Edit sx={{ color: "#2e7d32" }} />
          </ListItemIcon>
          Edit
        </MenuItem>,
      ].filter(Boolean);
    },
    muiTablePaperProps: () => ({ className: "DataTableComponentContainer" }),
  });

  return <MaterialReactTable table={table} />;
};

export default DataTable;
